body {
  position: relative;
  font-family: ZOZOGothic-Bold, sans-serif !important;
}

html {
  height: 100%;
  overflow-x: hidden;
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
  box-sizing: border-box;

  @media only screen and (max-width: $main-content-bp) {
    font-size: 50%; // 1rem = 8px
  }

  @media only screen and (max-width: $sm) {
    font-size: 43.375%; // 1rem = 7px
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: ZOZOGothic-Bold, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

.no-scroll {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
