// COLOR VARIABLES
$color-white: #fff;
$color-black: #000;
$color-grey: #808080;
$color-background: #f0f0f0;
$color-border: #ddd;

// BREAK POINTS
$xl: 1200px;
$main-content-bp: 1000px;
$lg: 992px;
$md: 768px;
$sm: 600px;
