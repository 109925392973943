.header {
  color: $color-black;
  line-height: 2;
}

.my-navbar-border {
  border-bottom: 1px solid $color-border;
}

.my-navbar {
  display: flex;
  align-items: center;

  .zz-button:hover {
    background-color: #f5f5f5 !important;
  }

  & &__item {
    flex-shrink: 0;
    padding: 12px 21px;
    font-size: 17px;
    line-height: 31px;

    &--logo {
      padding-left: 0;
    }
  }

  &__logo {
    width: 101px;
    height: 31px;
    line-height: 29px;
  }

  &__toggle-menu-btn {
    padding: 6px 12px !important;
    font-size: 20px;

    .close-menu-btn {
      // font-size: 24px;
      width: 18px;
      height: 18px;
    }
  }
}

.my-small-navbar {
  position: fixed;
  left: 0;
  z-index: 11;
  width: 100vw;
  background-color: $color-background;
  padding-top: 145px;
  padding-bottom: 24px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  &.is-active {
    opacity: 1;
    visibility: visible;
    transform: translate(0);
  }

  &__item {
    font-size: 18px;
    line-height: 30px;
    padding: 13px 23px !important;
    border-bottom: 1px solid #e5e7eb !important;
  }

  &__icon-box {
    margin-top: 7px;
    padding: 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    height: 24px;
    padding: 0 12px;
  }

  &__copy-right {
    font-size: 9px;
    line-height: 16px;
    color: $color-grey;
    text-align: center;
  }
}

.my-footer {
  margin-top: 10.7rem;
  background-color: $color-background;

  &__wraper {
    padding: 58px 44px 30px;
  }

  &__link {
    font-size: 2.2rem;
    line-height: 3.7rem;
    text-decoration: none;
    padding: 11.5px 0;
    border-bottom: 1px solid $color-border;
    width: 100%;
    display: block;
  }

  &__contact-info {
    margin-top: 6rem;
    padding: 3.1rem 0;

    &-row {
      display: flex;
      justify-content: center;
    }

    &-item {
      text-align: center;
      font-size: 15px;
      line-height: 22px;
      padding: 0 8px;
    }
  }

  &__icon-box {
    margin: 5rem 0 7rem;
    display: flex;
    justify-content: center;
  }

  &__icon {
    height: 3.4rem;
    padding: 0 16px;
  }

  &__copy-right {
    font-size: 13px;
    line-height: 13px;
    color: $color-grey;
    text-align: center;
  }
}

@media only screen and (max-width: $main-content-bp) {
  .my-navbar {
    padding: 0 23px;
  }

  .my-footer {
    &__wraper {
      padding-left: 23px;
      padding-right: 23px;
    }

    &__contact-info-row {
      display: block;
    }
  }
}

@media only screen and (max-width: $md) {
  .my-navbar {
    & &__item {
      padding: 12px 14px;

      &--logo {
        padding-left: 0;
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .my-navbar {
    justify-content: space-between;
    padding-right: 0;
  }

  .my-footer {
    margin-top: 57px;

    &__link {
      font-size: 18px;
    }

    &__contact-info {
      margin-top: 45px;

      &-item {
        font-size: 12px;
        line-height: 21px;
        padding: 0;
      }
    }

    &__copy-right {
      font-size: 9px;
      line-height: 16px;
    }
  }
}
