.container {
  max-width: 950px;
  margin: 0 auto;
}

.text-default {
  font-size: 2rem;
  line-height: 3.2rem;
}

.my-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.navbar {
  &__toggle-btn-container {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    width: 45px;
    height: 56px;
    padding: 0 !important;
  }

  &__toggle-btn {
    position: relative;
    z-index: 12;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background-color: transparent;
    cursor: pointer;
  }

  &__toggle-btn-line {
    position: absolute;
    left: 10px;
    right: 10px;
    z-index: 1;
    display: block;
    height: 2px;
    background-color: #1e1e1e;
    transition: all 0.45s;

    &:first-of-type {
      top: 16px;
    }

    &:nth-of-type(2) {
      top: 24px;
    }

    &:last-of-type {
      top: 32px;
    }

    &.is-active {
      &:first-of-type {
        transform: translateY(9px) rotate(-45deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:last-of-type {
        transform: translateY(-7px) rotate(45deg);
      }
    }
  }
}

#header {
  z-index: 12;
}

#mask-effect {
  z-index: 10;
}

.my-section {
  margin-top: 11.6rem;
  position: relative;

  &__anchor {
    position: absolute;
    top: -80px;
  }

  &__banner {
    min-height: 351px;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 10.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    position: relative;

    &-overlay {
      background-color: $color-black;
      opacity: 0.25;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    &-title {
      font-size: 7rem;
      line-height: 1;
      text-align: center;
      color: $color-white;
      z-index: 10;
    }
  }

  &__title {
    font-size: 4rem;
    line-height: 1;
    text-align: center;
  }

  &__content {
    margin-top: 5.3rem;

    &-item {
      margin-top: 1.6rem;
      font-size: 2rem;
      line-height: 3.2rem;
    }
  }
}

@media only screen and (max-width: $main-content-bp) {
  .container {
    & > * {
      padding: 0 23px;
    }
  }

  .my-section__banner {
    border-radius: 0;
    height: 375px;

    &-overlay {
      border-radius: 0;
    }
  }
}

@media only screen and (max-width: $sm) {
  .my-section {
    margin-top: 45px;

    &__banner {
      margin-bottom: 48px;
    }

    &__title {
      font-size: 28px;
      line-height: 33px;
    }

    &__content {
      margin-top: 40px;

      &-item {
        font-size: 14px;
        line-height: 21px;
        margin-top: 20px;
      }
    }
  }

  .text-default {
    font-size: 14px;
    line-height: 21px;
  }
}

.swiper-container {
  width: 100%;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    opacity: 1;
    cursor: pointer;
    margin: 0 7px !important;
    padding: 0;
    border-radius: 50%;
    border: solid 1px #fff;
    background-color: transparent;
    transition: all 0.1s;

    &.swiper-pagination-bullet-active {
      color: #000;
      background: #fff;
    }

    &:hover {
      background: #fff;
    }
  }
}

.arrow-right-icon {
  padding: 0;
  width: 24px;
  height: 24px;

  &__container {
    padding-left: 0 !important;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: $sm) {
  .arrow-right-icon {
    &__container {
      position: absolute;
      width: 34px !important;
      height: 34px;
      right: 0;
      top: calc(50% - 17px);
      margin-right: -12px;
      display: block;
    }
  }
}
