.home-page {
  padding-top: 56px;
  color: $color-black;
  line-height: 2;

  &__banner {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;

    &-indicator-box {
      bottom: 15px;
    }

    .home-page__banner-indicator {
      width: 13px;
      height: 13px;
      cursor: pointer;
      margin: 0 3px;
      padding: 0;
    }
  }

  &__top-anchor {
    position: absolute;
    top: -80px;
  }

  &__banner-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #map {
    width: 100%;
    height: 359px;
  }
}

.home-page-about {
  &__text {
    &--show-on-sm {
      display: none;
    }
  }
}

.home-page-technologies {
  &__banner {
    background-image: url('/public/images/pc/technologies.webp');
  }

  &__ability {
    margin: 16px 0 16px 22px;
  }

  &__discover {
    padding: 20px 0;
    text-align: center;
    background-color: $color-background;
    margin-top: 50px;
    border-radius: 5px;

    &-link {
      text-transform: uppercase;
      text-decoration: underline;
    }
  }

  &__title {
    &--show-on-sm {
      display: none;
    }
  }
}

.home-page-careers {
  &__banner {
    background-image: url('/public/images/pc/careers.webp');
  }

  &__text-item {
    margin-top: 16px;
  }

  &__job {
    margin-top: 45px;
    border-top: 1px solid $color-border;

    .job-item {
      display: flex;
      padding: 0 14px;
      border-bottom: 1px solid $color-border;
      text-decoration: none;
      position: relative;

      & > * {
        padding: 12px 24px;
        text-decoration: none;
      }

      &__link {
        width: 35%;
        flex-shrink: 0;
      }

      &__skills {
        width: 62%;
        flex-shrink: 0;
      }

      &__arrow {
        width: 5%;
        padding-left: 0;
      }

      i {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
        font-size: 20px;
        padding: 0 24px 0 0;
      }
    }
  }
}

.home-page-contact {
  &__banner {
    background-image: url('/public/images/pc/contact.webp');
  }

  &__info {
    margin-top: 52px;
  }
}

@media only screen and (max-width: $sm) {
  .home-page {
    &__banner {
      height: 375px;
    }
  }

  .about-us-title {
    position: relative;
    top: 2px;
  }

  .home-page-technologies {
    &__banner {
      background-image: url('/public/images/sp/technologies.webp');
    }

    &__discover {
      margin-top: 36px;
      line-height: 30px;
    }
  }

  .home-page-careers {
    &__banner {
      background-image: url('/public/images/sp/careers.webp');
    }

    .job-item {
      display: block;
      text-decoration: none;
      padding: 6px 0;

      &__link {
        font-size: 21px;
        line-height: 44px;
        width: 90%;
      }

      & > * {
        padding: 12px 0;
        width: 100%;
      }

      &__skills {
        padding-top: 0 !important;
        width: 90%;
      }

      i {
        padding-right: 0;
      }
    }
  }

  .home-page-contact {
    &__banner {
      background-image: url('/public/images/sp/contact.webp');
    }
  }
}

@media only screen and (max-width: 500px) {
  .home-page-about {
    &__text {
      max-width: 300px;
      margin: 0 auto;

      &--hide-on-sm {
        display: none;
      }

      &--show-on-sm {
        display: block;

        &-item {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }

  .home-page-technologies {
    &__title {
      &--hide-on-sm {
        display: none;
      }

      &--show-on-sm {
        display: block;
      }
    }
  }
}
