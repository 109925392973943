.job-page {
  min-height: 100vh;
  padding-top: 56px;
  color: $color-black;
  line-height: 2;

  &__title {
    font-size: 40px;
    line-height: 40px;
    margin-top: 6rem;
    margin-bottom: 5.5rem;
    text-align: center;
    padding: 0 20px;
  }

  &__banner {
    margin-top: 5.5rem;
    padding-top: 0;
  }

  &__banner-image {
    margin-bottom: 0;

    &--machine-learning-engineer {
      background-image: url('/public/images/pc/job.webp');
    }
  }

  &__introduce {
    margin-top: 5.5rem;

    &-item {
      margin-bottom: 2rem;
    }
  }

  &__apply {
    margin-top: 6.5rem;
  }

  .job-page-apply {
    display: flex;
    flex-direction: column;

    &__text {
      font-size: 15px;
      line-height: 21px;
      text-align: center;
    }

    &__btn {
      margin: 40px auto 0;
      height: 6rem;
      width: 40rem;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.job-page-section {
  margin-top: 5.5rem;

  &__title {
    font-size: 2.7rem;
  }

  &__sub-title {
    font-size: 2.7rem;
    margin-top: 2.6rem;

    &:nth-child(2) {
      margin-top: 5px;
    }
  }

  &__content {
    margin-top: 1.8rem;
    padding-left: 20px;
  }
}

@media only screen and (max-width: $main-content-bp) {
  .job-page-section {
    &__content {
      padding-left: 40px;
    }
  }
}

@media only screen and (max-width: $sm) {
  .job-page {
    &__title {
      font-size: 37px;
      line-height: 42px;
    }

    &__banner-image {
      height: 375px;

      &--machine-learning-engineer {
        background-image: url('/public/images/sp/job.webp');
      }
    }

    &__apply {
      margin-top: 5.5rem;
    }

    .job-page-apply {
      &__text {
        font-size: 12px;
        line-height: 21px;
        text-align: center;
      }

      &__btn {
        width: 187px;
      }
    }
  }
}
