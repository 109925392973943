.zz-top,
.zz-bottom {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.zz-top {
  top: 0;
}

.zz-bottom {
  bottom: 0;
}

.zz-center {
  text-align: center !important;
}

.zz-round {
  border-radius: 4px;
}

.zz-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.zz-button:hover {
  color: #000 !important;
  background-color: #ccc !important;
}

.zz-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.zz-card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.zz-bar {
  width: 100%;
  overflow: hidden;
}

.zz-center .zz-bar {
  display: inline-block;
  width: auto;
}

.zz-bar .zz-bar-item {
  padding: 8px 16px;
  float: left;
  width: auto;
  border: none;
  display: block;
  outline: 0;
}

.zz-bar .zz-button {
  white-space: normal;
}

.zz-bar-block .zz-bar-item {
  width: 100%;
  display: block;
  padding: 8px 16px;
  text-align: left;
  border: none;
  white-space: normal;
  float: none;
  outline: 0;
}

.zz-bar-block.zz-center .zz-bar-item {
  text-align: center;
}

.zz-white,
.zz-hover-white:hover {
  color: #000 !important;
  background-color: #fff !important;
}

.zz-black,
.zz-hover-black:hover {
  color: #fff !important;
  background-color: #000 !important;
}

.zz-image {
  max-width: 100%;
  height: auto;
}

.zz-animate-top {
  position: relative;
  animation: animatetop 0.4s;
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@media (min-width: 993px) {
  .zz-hide-large {
    display: none !important;
  }
}

@media (max-width: 992px) and (min-width: 601px) {
  .zz-hide-medium {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .zz-hide-small {
    display: none !important;
  }
}

.fa-angle-right::before {
  content: ‘\f105’;
}
